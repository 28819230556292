import AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import type { components } from './api/types';
import { apiDecoder } from './api';

export enum LoginStatus {
    LOGGED_OUT,
    LOGGED_IN,
    NEW_PASSWORD_REQUIRED,
    PASSWORD_CHANGE_FAILED,
}

export type LoggedIn = {
    status: LoginStatus.LOGGED_IN;
    token: string;
    refreshToken: string;
    user: AmazonCognitoIdentity.CognitoUser;
};
export type LoggedOut = { status: LoginStatus.LOGGED_OUT; error?: unknown };
export type NewPasswordRequired = {
    status: LoginStatus.NEW_PASSWORD_REQUIRED;
    user: AmazonCognitoIdentity.CognitoUser;
};
export type PasswordChangeFailed = {
    status: LoginStatus.PASSWORD_CHANGE_FAILED;
    user: AmazonCognitoIdentity.CognitoUser;
    error: unknown;
    userError: string;
};
export type UserLoginStatus =
    | LoggedIn
    | LoggedOut
    | NewPasswordRequired
    | PasswordChangeFailed;

export type WebsocketMessage = components['schemas']['WebsocketMessage'];
export type CreatedPresentationMessage =
    components['schemas']['CreatedPresentationMessage'];
export type NewPresentationSettings =
    components['schemas']['NewPresentationSettings'];
export type NewPersonSettings = components['schemas']['NewPersonSettings'];
export type SimplePresentationSettings =
    components['schemas']['SimpleNewPresentationSettings'];
export type SimplePresentationKind =
    components['schemas']['SimplePresentationKind'];
export type NewDallicPartySettings =
    components['schemas']['NewDallicPartySettings'];
export const SimplePresentationKind = apiDecoder.SimplePresentationKind.enum;
export const PresentationTone = apiDecoder.PresentationTone.enum;
export type PresentationTone = components['schemas']['PresentationTone'];
export type Suggestion = components['schemas']['Suggestion'];
export type PersonData = components['schemas']['PersonData'];
