import { z } from 'zod';

const mode = z
    .enum(['presentation', 'story', 'darktale', 'xmas'])
    .catch('presentation');

export type Settings = {
    onScreenKeyboard: boolean;
    prefillUsername: string;
    autoplayPresentations: boolean;
    mode: z.output<typeof mode>;
};

const url = new URL(document.location.href);
export const settings: Settings = {
    onScreenKeyboard: url.searchParams.get('osk') !== null,
    prefillUsername: url.searchParams.get('user') ?? '',
    autoplayPresentations: url.searchParams.get('autoplay') !== null,
    mode: mode.parse(url.searchParams.get('mode')),
};
