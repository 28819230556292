<script lang="ts">
    import type { PresentationJsonSlide } from '../../../logic/presentationJson';

    export let slide: PresentationJsonSlide;
</script>

<div class="column">
    <h2 class="headline">{slide.text}</h2>
</div>

<style>
    .headline {
        font-size: 7dvw;
        font-family: 'HeaderFont', sans-serif;
    }

    .column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center center;
        height: 100dvh;
        width: 100dvw;
        text-align: center;
        padding: 5dvw;
    }
</style>
