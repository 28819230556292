<div class="column">
    <h2 class="headline">Thank You!</h2>
</div>

<style>
    .headline {
        font-size: 7dvw;
        font-family: 'HeaderFont', sans-serif;
    }

    .column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 2;
        height: 100dvh;
        width: 100dvw;
        text-align: center;
        padding: 5dvw;
    }
</style>
