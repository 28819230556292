<script lang="ts">
    import { linear } from 'svelte/easing';
    import { fly } from 'svelte/transition';
    import FinalSlide from './FinalSlide.svelte';
    import TitleSlide from './TitleSlide.svelte';
    import RegularSlide from './RegularSlide.svelte';
    import type { PresentationJsonSlide } from '../../../logic/presentationJson';
    import { SlideDisplayTag } from '../../../logic/slideDisplay';

    export let state: SlideDisplayTag;
    export let slide: PresentationJsonSlide;
    export let index: number;

    $: first =
        state === SlideDisplayTag.FIRST_SLIDE_PLAYING ||
        state === SlideDisplayTag.FIRST_SLIDE_PAUSED;
    $: last =
        state === SlideDisplayTag.LAST_SLIDE_FINISHED ||
        state === SlideDisplayTag.LAST_SLIDE_PAUSED ||
        state === SlideDisplayTag.LAST_SLIDE_PLAYING;
    $: done = state === SlideDisplayTag.LAST_SLIDE_FINISHED;
</script>

<div
    class="slide"
    style={done
        ? 'filter: blur(0.5rem); transform: scale(1.1); transition: transform 1.0s, filter 1.0s;'
        : ''}
    in:fly|local={{
        duration: 800,
        x: '110vw',
        y: 0,
        easing: linear,
    }}
    out:fly|local={{
        duration: 800,
        x: '-110vw',
        y: 0,
        easing: linear,
    }}
>
    {#if first}
        <TitleSlide {slide} />
    {:else if last}
        <FinalSlide />
    {:else}
        <RegularSlide {slide} {index} />
    {/if}
</div>

<style>
    @font-face {
        font-family: 'HeaderFont';
        font-style: normal;
        font-weight: 400;
        src: local('HeaderFont'),
            url('/font/Anton-Regular.ttf') format('truetype');
    }

    .slide {
        color: white;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        gap: 5dvw;
        justify-content: center;
        align-items: center;
        height: 100dvh;
        width: 100dvw;
    }
</style>
