<script lang="ts">
    import { onMount } from 'svelte';
    import { useNavigate } from 'svelte-navigator';
    import RenderPresentation from '../../../common/renderPresentation/RenderPresentation.svelte';
    import AudioVideoPlayer from '../../../common/renderPresentation/AudioVideoPlayer.svelte';
    import KeyboardControls from '../../../common/renderPresentation/KeyboardControls.svelte';
    import Controls from './Controls.svelte';

    export let id: string;
    export let baseStoryUrlPath: string;

    const navigate = useNavigate();

    function goBack() {
        navigate(`/${baseStoryUrlPath}`);
    }

    onMount(() => {
        if (!id) {
            goBack();
            return;
        }
    });
</script>

<RenderPresentation
    presentationIndexURL={`${document.location.origin}/presentations/${id}/index.json`}
>
    <svelte:fragment
        slot="controls"
        let:nextSlide
        let:previousSlide
        let:pause
        let:play
        let:restart
        let:slideState
    >
        <AudioVideoPlayer
            {slideState}
            {nextSlide}
            {play}
            autoPlay={true}
            slideTransitionDelay={1000}
        />
        <KeyboardControls
            {nextSlide}
            {previousSlide}
            {pause}
            {play}
            {restart}
            {slideState}
        />
        <Controls {restart} {slideState} {goBack} {play} />
    </svelte:fragment>
</RenderPresentation>
