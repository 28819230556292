import aiSlidesAudio from '../../../kiosk/assets/audio/de/ai-slides.mp3';
import gpt4Audio from '../../../kiosk/assets/audio/de/gpt4.mp3';
import stableDiffusionAudio from '../../../kiosk/assets/audio/de/stableDiffusion.mp3';
import pollyAudio from '../../../kiosk/assets/audio/de/polly.mp3';
import azureAudio from '../../../kiosk/assets/audio/de/azure.mp3';
import type { LocalizationSettings } from './types';
import { PresentationTone } from '../../types';

const de: LocalizationSettings = {
    key: 'de',
    languageLabel: 'Deutsch',
    languageNameForGeneration: 'German',
    switchLabel: 'Spracheinstellung',
    rotatePrompt: 'Bitte drehen Sie ihr Gerät, um die Präsentation zu sehen',
    overview: {
        title: 'AI Slides',
        tagline: 'KI erstellt automatisch Präsentationen',
        newPresentation: 'eigene Präsentation erstellen',
    },
    newPresentation: {
        createPresentationTitle: 'Erstelle eine Präsentation mit KI',
        presentationTitle: 'Denk dir einen Titel aus',
        toneErnest: 'ernst',
        toneFunny: 'albern',
        createButton: 'Erstellen',
        presentationStyle: 'Präsentationsstil',
        topic: 'zum Thema „{topic}“',
        backToLastPresentation: 'zurück zu den Präsentationen',
        backToOverview: 'zurück zur Übersicht',
    },
    newStory: {
        createStory: 'Was soll ich dir erzählen?',
        storyReady: 'Fertig! Soll ich vorlesen?',
        creationFailed: 'Ups, da ist etwas schief gelaufen.',
        titleLabel: 'Dein Titel',
        submitButtonText: "Los geht's",
        readOutButtonText: 'Vorlesen',
        retryButtonText: 'Nochmal probieren',
    },
    newDallicParty: {
        initialPrompt: 'Initaler Prompt',
        changePrompt: 'Veränderungs-Prompt',
        numberOfIterations: 'Anzahl der Iterationen',
    },
    presentationReady: {
        goToPresentation: 'Präsentation anschauen',
    },
    generationFailed: {
        headline: 'Es tut uns leid, die Erstellung ist fehlgeschlagen',
        spicyHeadline:
            'Der angegebene Titel wurde als potentiell anstößig erkannt',
        retryPrompt: 'Bitte versuchen Sie es mit einem anderen Thema',
        quotaExceeded:
            'Das Limit für die Erstellung von Präsentationen wurde erreicht',
    },
    presentation: {
        jumpToOverview: 'zur Übersicht',
        jumpToNext: 'zur nächsten Präsentation',
        createYourOwn: 'eigene Präsentation erstellen',
        confirmTitle: 'Präsentation beendet',
        confirmNext: 'weiter zur nächsten Präsentation',
        replay: 'noch einmal anschauen',
        confirmCreateOwn: 'Möchten Sie Ihre eigene Präsentation erstellen?',
        confirmCreateOwnAction: 'eigene Präsentation erstellen',
        declineCreateOwnAction: 'weiter diese Präsentation anschauen',
    },
    story: {
        childBookTitle: 'Der automatische Geschichtenerzähler',
        darkTalesTitle: 'Dark Tales',
        christmasTitle: 'Der mechanische Weihnachtsmann',
        hearAgainButtonText: 'Noch einmal anhören',
        backButtonText: 'Zurück zum Hauptmenü',
        playButtonText: 'Abspielen',
    },
    upload: {
        dragDropImage:
            'Ziehen Sie ein Bild hierher oder klicken Sie, um auszuwählen',
        dragDropAudio:
            'Ziehen Sie eine Audiodatei hierher oder klicken Sie, um auszuwählen',
        altImage: 'Porträt',
        audioNotSupported: 'Ihr Browser unterstützt kein Audio',
    },
    record: {
        startRecording: 'Aufnahme starten',
        portrait: 'Portrait-Bild',
        takeSnapshotHeader: 'Bild aufnehmen',
        takeSnapshot: 'Bild aufnehmen',
        acceptSnapshotHeader: 'Bild überprüfen',
        acceptSnapshot: 'Bild akzeptieren',
        retakeImage: 'Bild nochmal aufnehmen',
        audioRecordingHeader: 'Audio aufnehmen',
        readText:
            'Früh am Morgen erwacht die Stadt mit dem Klang belebter Straßen und fröhlichen Vögeln. Die Menschen eilen zur Arbeit, trinken Kaffee und begrüßen sich mit einem Lächeln. Im Park rennen Hunde frei herum, ihren Lieblingsspielzeugen hinterherjagend. Die Luft ist frisch, gefüllt mit dem Duft blühender Blumen und frisch gebackenem Brot aus der nahegelegenen Bäckerei. Das Leben in der Stadt pulsiert, und jede Ecke bietet eine neue Geschichte zum Entdecken.',
        startAudioRecording: 'Audioaufnahme starten',
        stopAudioRecording: 'Audioaufnahme stoppen',
        acceptAudioHeader: 'Audio überprüfen',
        acceptAudioRecording: 'Audio akzeptieren',
        retakeAudioRecording: 'Audio nochmal aufnehmen',
        cancel: 'Abbrechen',
    },
    info: {
        aiSlides: {
            title: 'AI Slides',
            text: [
                'AI-Slides ist ein Programm der TNG Technology Consulting GmbH. Es nutzt künstliche Intelligenz, um aus einer Nutzereingabe eine ganze Präsentation zu erstellen. Viele verschiedene KI-Technologien arbeiten dafür zusammen. Der Nutzer gibt einen Titel für die Präsentation ein und bestimmt, ob sie lustig oder ernst sein soll. Diese Informationen gehen dann an [GPT-4](gpt4), eine KI, die Texte erstellen kann. [GPT-4](gpt4) gibt dann Überschriften für jede Folie, den Text, der gesprochen wird, und Beschreibungen von Bildern aus, die zu jeder Folie passen würden.',
                'Der Text, der gesprochen wird, wird dann noch einmal bearbeitet. Entweder [Amazon Polly](polly) oder [Azure Text-to-Speech](azure) wandeln den Text in gesprochene Sprache um. Beide Systeme können aus Text Sprache machen, die fast wie von einem Menschen klingt.',
                'Außerdem nutzt das Programm die von [GPT-4](gpt4) gegebenen Beschreibungen der Bilder. Ein weiteres KI-Programm namens [Stable Diffusion](stableDiffusion) erstellt dann anhand der Beschreibungen passende Bilder für jede Folie.',
                'Sind alle Elemente ‒ gesprochener Text, Bilder und der Text auf den Folien ‒ fertig, werden sie zu einer fertigen Präsentation zusammengefügt. Diese Präsentation kann man sich dann im Internet anschauen. So kann man schnell und einfach mit einer kurzen Nutzereingabe eine ganze Präsentation erstellen.',
            ],
            audioFile: aiSlidesAudio,
        },
        gpt4: {
            title: 'GPT-4',
            text: [
                'GPT-4 steht für Generative Pretrained Transformer 4 und ist ein Künstliche-Intelligenz-System, das dazu entwickelt wurde, Texte zu erstellen. Diese KI nutzt ein Netzwerk von Algorithmen, ähnlich wie das menschliche Gehirn ein Netzwerk von Neuronen nutzt, um basierend auf einer gegebenen Eingabe Text zu bewerten, zu verstehen und zu generieren. Sie tut dies, indem sie Muster und Beziehungen zwischen Worten, Sätzen und ganzen Textabschnitten in einer riesigen, diversen Internettextdatenbank findet, mit der sie trainiert wurde.',
                'GPT-4 generiert Antworten auf eine Eingabe, indem sie das, was sie gelernt hat, nutzt, um das nächste Wort in einem Satz vorherzusagen. Dies ähnelt einem Spiel, das wir Menschen spielen könnten, bei dem man das nächste Wort in einem Satz vorhersagen muss. Sie prognostiziert, was als nächstes kommt, indem sie die Wahrscheinlichkeit berechnet, welches Wort oft nach dem letzten Wort in der Eingabe oder im Satz kommt.',
                'Was GPT-4 so beeindruckend macht, ist, dass nicht nur grammatikalisch korrekte Texte verfasst, sondern auch den Kontext, über den es schreibt, erahnen kann. Sie kann detaillierte Antworten erstellen, die ein Verständnis für das Thema, Kreativität und sogar Meinungen zum Ausdruck bringen. Dies vermittelt den Eindruck, als wäre der Text von einem Menschen geschrieben worden.',
            ],
            audioFile: gpt4Audio,
        },
        stableDiffusion: {
            title: 'Stable Diffusion',
            text: [
                'Stable Diffusion, ein KI-basierter Bildgenerator, arbeitet nach einer ähnlichen Methode wie [Textgeneratoren](gpt4), allerdings mit visuellen Daten anstelle von linguistischen Daten. Die primäre Art, wie er Bilder aus einer Eingabe generiert, besteht darin, die Eingabe in eine Erkundungsreise vom Rauschen zum finalen Ausgabe-Bild umzuwandeln, das wir sehen.',
                'Man kann sich den Bildgenerierungsprozess so vorstellen, als würde man eine leere Leinwand mit Ideen aus einer direkten Eingabe füllen. Statt jedoch von nichts anzufangen und direkt Details hinzuzufügen, beginnt die Stable Diffusion mit einer Leinwand voller zufälligen Rauschens, bzw. "Störungen", und verfeinert dieses schrittweise zu einem kohärenten Bild.',
                'Die Verbesserung erfolgt in winzigen Schritten. Bei jedem Schritt überarbeitet die KI einen kleinen Teil des Bildes basierend auf zwei Informationen: dem Gesamtziel, auf das sie abzielt (das mit der Eingabe zusammenhängt), und der Notwendigkeit, das resultierende Bild so realistisch wie möglich wirken zu lassen. Dieser Prozess wird oft hunderte Male wiederholt, bis das Rauschen in ein Bild transformiert wurde, das sowohl realistisch aussieht als auch mit der ursprünglichen Eingabe übereinstimmt. Das Lernen der KI erfolgt in einer ähnlichen Weise wie das von GPT-4: sie hat viele Bilder gesehen und verstanden, wie verschiedene Details und Strukturen zusammenpassen, um realistische Bilder zu formen, und sie verwendet dieses Wissen, um die Eingabe zu interpretieren und darauf zu reagieren.',
            ],
            audioFile: stableDiffusionAudio,
        },
        polly: {
            title: 'Polly',
            text: [
                'Amazon Polly, ein Service von Amazon Web Services (AWS), ist eine Technologie, die Text in natürliche Sprache umwandelt. Denken Sie daran als einen ausgezeichneten Vorleser, der geschriebenen Text in einer menschenähnlichen Stimme vorlesen kann. Das Ziel ist es, eine ansprechende Benutzererfahrung mit Anwendungen wie Nachrichtenlesern, Sprachübersetzern und Sprachassistenten zu schaffen.',
                'Im Kern verwendet Amazon Polly fortschrittliche Deep-Learning-Technologien. Es beginnt damit, den Eingabetext zu analysieren, um Worte, Zeichensetzung und den Satzbau zu identifizieren - dieser Schritt wird Textanalyse genannt. Sobald der Text auf diese Weise zerlegt ist, erzeugt Amazon Polly die Sprache, die dem Text entspricht. Dieser Prozess wird als Sprachsynthese oder Text-zu-Sprache (TTS) bezeichnet.',
                'Was Amazon Polly auszeichnet, ist seine Fähigkeit, hochwertige Sprachausgabe in vielen verschiedenen Sprachen und Dialekten zu liefern. Es kann auch Prosodie-Elemente ausführen, das bedeutet, es kann die Tonhöhe, das Tempo und die Lautstärke der Sprache verändern, um eine natürlichere und ausdrucksstärkere Sprachausgabe zu erzeugen. Zum Beispiel kann es seine Stimme fragend klingen lassen, wenn es auf ein Fragezeichen trifft, oder Begeisterung ausdrücken, wenn es ein Ausrufezeichen liest. Es atmet sogar mit angemessenen Pausen zwischen den Sätzen für ein noch realistischeres Sprachergebnis.',
            ],
            audioFile: pollyAudio,
        },
        azure: {
            title: 'Azure TTS',
            text: [
                'Azure Text-to-Speech (TTS), ein Dienst von Microsoft Azure, ist eine fortschrittliche Technologie, die geschriebenen Text in gesprochene Wörter umwandelt. Es handelt sich um einen virtuellen Erzähler, der den Text auf eine sehr menschlichen klingende Weise vorliest. Azure Text-to-Speech soll die Benutzerinteraktion mit Anwendungen wie E-Books, unterstützenden Technologien oder Navigationssystemen interaktiver und intuitiver gestalten.',
                'Die Funktionsweise von Azure TTS basiert im Wesentlichen auf zwei primären Schritten: Textanalyse und Sprachsynthese. Im ersten Schritt nimmt das System den eingegebenen Text und zerlegt ihn in kleinere verständliche Einheiten, erkennt die Sprache, identifiziert die Satzzeichen und teilt den Text in sinnvolle Phrasen auf. Im zweiten Schritt verwendet Azure diese analysierten Teile, um mit Hilfe eines "Text-zu-Sprache"-Prozesses gesprochene Wörter zu erzeugen, die dem geschriebenen Text entsprechen.',
                'Was Azure TTS besonders macht, sind Funktionen wie „Neural Voices“, die tiefe neuronale Netzwerke nutzen, um menschenähnliche Stimmen zu produzieren, und „Custom Voice“, eine Funktion, die es Ihnen ermöglicht, eine einzigartige Stimme für Ihre Marke zu erstellen. Es kann Emotionen und Absichten in der Sprache ausdrücken, seinen Ton, sein Tempo und seine Lautstärke modulieren und der synthetisierten Sprache natürliche Intonationen verleihen. Darüber hinaus kann es all dies in verschiedenen Sprachen und Dialekten tun, was es zu einem sehr vielseitigen Werkzeug macht.',
            ],
            audioFile: azureAudio,
        },
    },
    readInfo: 'vorlesen',
    pauseReading: 'pausieren',
    generatorMessages: {
        [PresentationTone.FUNNY]: {
            initial: 'KI startet durch – auf zur Präsentationserstellung!',
            messages: [
                'Brainstorming-Marathon: Welche Themen sind ein Hit?',
                'Detektivarbeit: Daten und Infos unter die Lupe nehmen',
                'Datenquellen-Party: Wer bringt die besten Infos?',
                'KI-Spürnase recherchiert wie ein Profi',
                'Farben und Stil – auf zur kreativen Modenschau!',
                'Tolle Layouts: Jede Folie soll glänzen!',
                'Schnörkel adé, wir schreiben fürs Auge',
                'Überschriften-Generator im Einsatz: Ordnung, Jungs und Mädels!',
                'Wer sind diese Menschen? Zielgruppenanalyse startet',
                'Grafik-Zauberei: So werden Daten zum Schmaus',
                'NLP-Textakrobatik – Sätze zum Verlieben',
                'Interaktivität und Animationen: Bühne frei!',
                'Medienzauber: Videos und Audio-Mixturen',
                'Text- und Grafiktuning: Perfektion in Arbeit!',
                'Daten-Snacks und Zusatzmaterialien zum Naschen',
                'Federleicht von Folie zu Folie hüpfen',
                'Sprache und Ton: von charmant bis amüsant',
                'Infografik-Spaß: Daten mit Augenzwinkern',
                'Statistiken-Prüfung: Fakten auf dem Silbertablett',
                'To-do-Listenquerweltein: Organisationswunder in Sicht!',
                'Call-to-Action-Wirbel: Interaktion mit Witz',
                'Bald gesellt sich Experten-Ruhm hinzu!',
                'Klick, Blitz, Foto! Bilder fürs Gedächtnis',
                'Präsentationsleben auf allen Geräten feiern!',
                'Barrierefreiheit, die Herzen öffnet',
                'Fußnoten und Literaturwürdigung: Quellen hochleben lassen!',
                'Optimierungs-KI: Magie im Maschinenlernen',
                'Benutzererfahrung: Rote Teppiche für alle!',
                'Anpassung: Vortragsstile auf dem Catwalk',
                'Feedback-Loop: Mit Freude Messer wetzen',
                'Goodies zum Mitnehmen: Handouts als Souvenir',
                'Social Media und Online: Lass die Vernetzung beginnen!',
                'Für jeden Topf ein süßes Deckelchen: Präsentationsanpassung',
                'Aktualisierungs-Maschine: Immer am Zahn der Zeit!',
                'Multi-Geräte-Akrobatik: Test am Limit',
                'Qualitätssicherung: Lachkrampf-Check inklusive',
                'Einsatzbereitschaft: Startschuss zur Präsentations-Parade',
                'Effektivitätssteigerung: Mit KI-Turbo!',
                'Lernfreude: Neues Wissen kommt rasant!',
                'Anpassung und Skalierung: Präsentation in Übergröße',
                'Sprachwirbel: Übersetzung in alle Ecken!',
                'Dateiformat-Reigen: Ein Format für jeden Anlass',
                'Sprachassistenten und Chatbots: Plaudertaschen im Team',
                'Fort Knox des Präsentationsschutzes',
                'Datenverlust? Nicht mit uns!',
                'Präsentations-Quantensprung durch KI und Kreativität',
                'Expertenvalidierung als Sahnehäubchen',
                'Aktualisierung mit Schwung und Feedback-Nektar',
                'Vorhang auf für die KI-generierte Präsentation – viel Vergnügen!',
            ],
        },
        [PresentationTone.ERNEST]: {
            initial:
                'Künstliche Intelligenz initialisiert, um Präsentation zu erstellen.',
            messages: [
                'Brainstorming-Prozess zur Themenfindung startet.',
                'Analyse relevanter Informationen und Daten.',
                'Verbindung zu Datenquellen hergestellt.',
                'Automatisierte Recherche zum Thema erfolgt.',
                'Entwerfen eines Farbschemas und visuellen Stils.',
                'Erstellung von Layouts für individuelle Folien.',
                'Textformatierung und Schriftauswahl optimiert.',
                'Überschriften und Zwischenüberschriften generiert.',
                'Zielgruppenanalyse durchgeführt.',
                'Anfertigung von Diagrammen und Grafiken.',
                'Erstellung verständlicher Sätze mittels NLP.',
                'Integration interaktiver Elemente und Animationen.',
                'Verknüpfung von Medien und Videos.',
                'Optimierung von Text und Grafiken.',
                'Generierung von Datenblättern und Zusatzmaterialien.',
                'Erstellung von Übergangselementen zwischen Folien.',
                'Anpassung von Sprache und Ton an Zielgruppe.',
                'Entwurf von Infografiken zur Informationsvermittlung.',
                'Überprüfung der Statistiken und Quellenangaben.',
                'Einfügen von To-do-Listen und Zusammenfassungen.',
                'Hinzufügen von Call-to-action-Elementen.',
                'Integration von Expertenmeinungen und Case-Studies.',
                'Einfügen von Fotos und Bildern.',
                'Anpassung der Präsentation an unterschiedliche Geräte.',
                'Implementierung von Barrierefreiheitsfunktionen.',
                'Fußnoten und Literaturangaben eingefügt.',
                'Anwendung von Machine Learning zur Optimierung.',
                'Evaluation der Benutzererfahrung.',
                'Anpassung an verschiedene Vortragsstile.',
                'Integration von Feedback-Funktionen.',
                'Erstellung von Ergänzungsmaterialien und Handouts.',
                'Einbindung von Social-Media- und Onlineplattformen.',
                'Personalisierung der Präsentation für Zielgruppen.',
                'Automatische Aktualisierung der Präsentation.',
                'Test auf verschiedenen Geräten und Software.',
                'Qualitätssicherung und Korrekturlesen.',
                'Funktionsumfang und Einsatzbereitschaft überprüft.',
                'Anwendung von KI-gestützter Effektivitätssteigerung.',
                'Kontinuierliches Lernen neuer Technologien und Methoden.',
                'Kapazitäten zur Anpassung und Skalierung geschaffen.',
                'Automatische Übersetzung in verschiedene Sprachen.',
                'Speicherung in verschiedenen Dateiformaten.',
                'Integration von Sprachassistenten und Chatbots.',
                'Schutz der Präsentation vor unbefugtem Zugriff.',
                'Sicherung der Präsentation gegen Datenverlust.',
                'Integration kreativer Ansätze und KI-generierter Inhalte.',
                'Validierung der Präsentation durch Experten.',
                'Aktualisierung basierend auf Feedback und Erkenntnissen.',
                'Abschluss des KI-generierten Präsentation und Vorbereitung zur Vorführung.',
            ],
        },
    },
    keyboardLayout: {
        default: [
            '^ 1 2 3 4 5 6 7 8 9 0 \u00DF \u00B4 {bksp}',
            'q w e r t z u i o p \u00FC !',
            'a s d f g h j k l \u00F6 \u00E4 ?',
            '{shift} < y x c v b n m , . - {shift}',
            '@ {space}',
        ],
        shift: [
            '\u00B0 ! " \u00A7 $ % & / ( ) = ? ` {bksp}',
            'Q W E R T Z U I O P \u00DC !',
            'A S D F G H J K L \u00D6 \u00C4 ?',
            '{shift} > Y X C V B N M ; : _ {shift}',
            '@ {space}',
        ],
    },
    keyboardLabels: {
        '{bksp}': '⌫',
        '{shift}': '⇧ Umschalt',
        '{space}': 'Leerzeichen',
    },
    landingpage: {
        login: {
            login: 'Einloggen',
            logout: 'Ausloggen',
            username: 'Accountname',
            password: 'Passwort',
            changePassword: 'Passwort ändern',
            newPassword: 'Neues Passwort',
            repeatNewPassword: 'Wiederholen',
            close: 'Schließen',
        },
        menu: {
            home: 'Startseite',
            suggestTopic: 'Thema vorschlagen',
            reviewSuggestions: 'Vorschläge ansehen',
            newPresentation: 'Neue Präsentation',
            newStory: 'Neue Geschichte',
            newDarkTale: 'Neues Dark Tale',
            newChristmasTale: 'Neue Weihnachtsgeschichte',
            newDallicParty: 'Neue Dallic Party',
        },
        footer: {
            imprint: 'Impressum',
            privacy: 'Datenschutz',
            language: 'Sprache ändern',
        },
        presentations: {
            favorite: 'favorisieren',
            unfavorite: 'aus Favoriten entfernen',
            delete: 'löschen',
            publish: 'Veröffentlichen',
            unpublish: 'depublizieren',
            failedToDelete: 'Löschen der Präsentation fehlgeschlagen',
            failedToUnpublish: 'Depublizieren der Präsentation fehlgeschlagen',
        },
        suggestTopic: {
            title: 'Vorschlag für ein Präsentationsthema',
            yourSuggestion: 'Dein Vorschlag',
            submitSuggestion: 'Vorschlag abschicken',
            suggestionSubmitted: 'Der Vorschlag wurde abgeschickt',
            suggestAnother: 'Noch einen Vorschlag machen',
            submissionFailed: 'Das hat leider nicht funktioniert',
            retry: 'Nochmal versuchen',
        },
        manageSuggestions: {
            title: 'Vorschläge verwalten',
            delete: 'Vorschlag löschen',
        },
        newPresentation: {
            title: 'Neue Präsentation',
            topic: 'Thema',
            generateTopic: 'Generiere ein Thema',
            useSuggestion: 'Verwende einen Vorschlag',
            numberOfSlides: 'Anzahl der Folien',
            narrationStyle: 'Erzählstil',
            personality: 'Persönlichkeit',
            customPersonality: 'Eigene Persönlichkeit',
            yourPersonality: 'Du',
            showPersonData: 'zeige Persönlichkeitsdaten',
            hidePersonData: 'verstecke Persönlichkeitsdaten',
            person: {
                name: 'Name',
                description: 'Beschreibung',
                speaker: 'Sprecher',
                imageDescription: 'Bildbeschreibung',
                fillBlanks: 'Fülle die Lücken',
                clear: 'Daten leeren',
                recording: 'Aufnahme',
                noRecording: 'Noch keine Aufnahme',
            },
            imageGenerator: 'Bildgenerator',
            videoGenerator: 'Videogenerator',
            textGenerator: 'Textgenerator',
            coverSlideVideo: 'Video für Titelfolie',
            createCoverSlideVideo: 'Erstelle Video für Titelfolie',
            content: 'Inhalt',
            moderateContent: 'Moderiere den Inhalt',
            promotion: 'Branding',
            noPromotion: 'Ohne Branding',
            createPresentation: 'Erzeuge Präsentation',
        },
    },
};
export default de;
