<script lang="ts">
    import { PresentationByKind } from './kinds';
    import AudioVideoPlayer from './AudioVideoPlayer.svelte';
    import RotatePrompt from './RotatePrompt.svelte';
    import KeyboardControls from './KeyboardControls.svelte';
    import {
        getPresentationLogic,
        isLoading,
        isLoadingError,
    } from './logic/pagerLogic';

    export let presentationIndexURL: string = '';

    const { slideState, nextSlide, previousSlide, play, restart, pause } =
        getPresentationLogic(presentationIndexURL);
</script>

{#if true}
    {@const state = $slideState}
    {#if isLoading(state)}
        <slot name="loading">Loading...</slot>
    {:else if isLoadingError(state)}
        <slot name="error" error={state.error}>
            {state.error?.message}
        </slot>
    {:else}
        <div class="presentation-panel" inert>
            <svelte:component
                this={PresentationByKind[state.presentation.kind]}
                slide={state}
            />
        </div>
        <div class="control-panel">
            <slot
                name="controls"
                {nextSlide}
                {previousSlide}
                {pause}
                {play}
                {restart}
                slideState={state}
            >
                <AudioVideoPlayer
                    {nextSlide}
                    {play}
                    slideState={state}
                    autoPlay={true}
                />
                <KeyboardControls
                    {nextSlide}
                    {previousSlide}
                    {pause}
                    {play}
                    {restart}
                    slideState={state}
                />
            </slot>
        </div>
        <RotatePrompt />
    {/if}
{/if}

<style>
    .presentation-panel {
        width: 100dvw;
        height: 100dvh;
        position: fixed;
        top: 0;
        left: 0;
        pointer-events: none;
    }

    .control-panel {
        width: 100dvw;
        height: 100dvh;
        position: fixed;
        top: 0;
        left: 0;
    }
</style>
