import type { Observable } from 'rxjs';
import {
    first,
    from,
    interval,
    map,
    share,
    startWith,
    switchMap,
    take,
    takeWhile,
    tap,
    timeout,
} from 'rxjs';
import { writable, type Writable } from 'svelte/store';
import type {
    NewPresentationSettings,
    SimplePresentationSettings,
} from '../../types';
import {
    LoginStatus,
    type UserLoginStatus,
    type WebsocketMessage,
} from '../../types';
import { webSocket } from 'rxjs/webSocket';
import { uploadPersonData } from '../../../landingpage/actions/uploadPersonData';

export enum CreatePresentationStateTag {
    IDLE = 'IDLE',
    CREATING = 'CREATING',
    CREATED = 'CREATED',
    ERROR = 'ERROR',
    DENIED = 'DENIED',
    QUOTA_EXCEEDED = 'QUOTA_EXCEEDED',
}

export type CreatePresentationIdle = {
    readonly tag: CreatePresentationStateTag.IDLE;
    readonly createPresentation: {
        (settings: NewPresentationSettings): void;
        (settings: SimplePresentationSettings, kind: 'simple'): void;
    };
};

export type CreatePresentationCreating = {
    readonly tag: CreatePresentationStateTag.CREATING;
    readonly progress: Observable<string>;
    readonly timeoutFraction: Observable<number>;
};

export type CreatePresentationCreated = {
    readonly tag: CreatePresentationStateTag.CREATED;
    readonly presentationId: string;
};

export type CreatePresentationError = {
    readonly tag: CreatePresentationStateTag.ERROR;
    readonly error: unknown;
    readonly retry: () => void;
};

export type CreatePresentationDenied = {
    readonly tag: CreatePresentationStateTag.DENIED;
    readonly reason: string;
    readonly retry: () => void;
};

export type CreatePresentationQuotaExceeded = {
    readonly tag: CreatePresentationStateTag.QUOTA_EXCEEDED;
    readonly retry: () => void;
};

export type CreatePresentationState =
    | CreatePresentationIdle
    | CreatePresentationCreating
    | CreatePresentationCreated
    | CreatePresentationError
    | CreatePresentationDenied
    | CreatePresentationQuotaExceeded;

function getWebsocketUrl(token: string) {
    const isSecure = /https/.test(window.location.protocol);
    return `ws${isSecure ? 's' : ''}://${window.location.hostname}:${
        window.location.port
    }/presentation?jwt=${token}`;
}

const PROGRESS_INTERVAL_MS = 1000;

export function getProgressFraction(
    timeoutMs: number,
    intervalMs = PROGRESS_INTERVAL_MS
): Observable<number> {
    return interval(intervalMs).pipe(
        take(timeoutMs / intervalMs),
        startWith(undefined),
        map((_, t) => (t * intervalMs) / timeoutMs)
    );
}

function getCreatePresentation(
    authenticationProvider: Observable<AuthenticatorState>,
    state: Writable<CreatePresentationState>,
    timeoutMS: number
) {
    function retry() {
        state.set({
            tag: CreatePresentationStateTag.IDLE,
            createPresentation: createNewPresentation,
        });
    }

    function createPresentation(
        token: string,
        kind: 'simple' | undefined,
        settings: NewPresentationSettings | SimplePresentationSettings,
        uploadData: {
            imageFile: string | undefined;
            audioFile: string | undefined;
        }
    ) {
        const url = getWebsocketUrl(token);

        const socket = webSocket<WebsocketMessage>(url);
        if (kind === 'simple') {
            socket.next({
                type: 'create-presentation-simple',
                payload: updateSettings(
                    settings as SimplePresentationSettings,
                    uploadData
                ),
            });
        } else {
            socket.next({
                type: 'create-presentation',
                payload: updateSettings(
                    settings as NewPresentationSettings,
                    uploadData
                ),
            });
        }
        return socket.pipe(
            tap((msg) => {
                if (msg.type === 'created-presentation') {
                    socket.complete();
                }
            })
        );
    }

    function updateSettings<
        PresentationSettings extends
            | NewPresentationSettings
            | SimplePresentationSettings
    >(
        settings: PresentationSettings,
        uploadData: {
            imageFile: string | undefined;
            audioFile: string | undefined;
        }
    ): PresentationSettings {
        settings.person.image = uploadData.imageFile;
        settings.person.audio = uploadData.audioFile;
        return settings;
    }

    function createNewPresentation(settings: NewPresentationSettings): void;
    function createNewPresentation(
        settings: SimplePresentationSettings,
        kind: 'simple'
    ): void;
    function createNewPresentation(
        settings: NewPresentationSettings | SimplePresentationSettings,
        kind?: 'simple'
    ) {
        const results = authenticationProvider.pipe(
            take(1),
            map((state) => {
                if (state.authenticated) {
                    return state.token;
                }
                throw new Error('User is not logged in!');
            }),
            switchMap((token) => {
                return from(
                    uploadPersonData(
                        settings.person.image,
                        settings.person.audio
                    )
                ).pipe(
                    switchMap((personDataUrls) => {
                        return createPresentation(
                            token,
                            kind,
                            settings,
                            personDataUrls
                        );
                    })
                );
            }),
            share()
        );

        const progressMessages = results.pipe(
            takeWhile((msg) => msg.type === 'progress'),
            map((msg) => {
                if (msg.type === 'progress') {
                    return msg.message;
                }
                return '';
            })
        );

        state.set({
            tag: CreatePresentationStateTag.CREATING,
            progress: progressMessages,
            timeoutFraction: getProgressFraction(timeoutMS),
        });

        const subscription = results.subscribe({
            next(msg) {
                function end(finalState: CreatePresentationState) {
                    state.set(finalState);
                    subscription.unsubscribe();
                }

                switch (msg.type) {
                    case 'abort':
                        return end({
                            tag: CreatePresentationStateTag.DENIED,
                            reason: msg.flaggingInformation,
                            retry,
                        });
                    case 'error':
                        return end({
                            tag: CreatePresentationStateTag.ERROR,
                            error: new Error(msg.message),
                            retry,
                        });
                    case 'created-presentation':
                        return end({
                            tag: CreatePresentationStateTag.CREATED,
                            presentationId: msg.id,
                        });
                    case 'quota':
                        return end({
                            tag: CreatePresentationStateTag.QUOTA_EXCEEDED,
                            retry,
                        });
                    case 'progress':
                        return;
                    default:
                        console.debug('unexpected message', msg);
                }
            },
            error(err) {
                state.set({
                    tag: CreatePresentationStateTag.ERROR,
                    error: err,
                    retry,
                });
            },
        });

        results
            .pipe(
                first((msg) => msg.type === 'created-presentation'),
                timeout(timeoutMS)
            )
            .subscribe({
                error() {
                    state.set({
                        tag: CreatePresentationStateTag.ERROR,
                        error: new Error('timeout'),
                        retry,
                    });
                    subscription.unsubscribe();
                },
            });
    }

    return createNewPresentation;
}

export type AuthenticatorState =
    | {
          readonly authenticated: true;
          readonly token: string;
      }
    | { readonly authenticated: false };

export function getPresentationCreator(
    authenticationProvider: Observable<AuthenticatorState>,
    timeout: number // timeout in ms
) {
    const state = writable<CreatePresentationState>();

    const createPresentation = getCreatePresentation(
        authenticationProvider,
        state,
        timeout
    );

    state.set({
        tag: CreatePresentationStateTag.IDLE,
        createPresentation,
    });

    return state;
}

export function fromLoginState(user: UserLoginStatus): AuthenticatorState {
    if (user.status === LoginStatus.LOGGED_IN) {
        return {
            authenticated: true,
            token: user.token,
        };
    } else {
        return {
            authenticated: false,
        };
    }
}
