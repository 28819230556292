<script lang="ts">
    import type { SlideDisplayState } from './logic/slideDisplay';
    import { SlideDisplayTag } from './logic/slideDisplay';
    import { onMount } from 'svelte';

    export let slideState: SlideDisplayState;
    export let nextSlide: () => void;
    export let play: () => void;
    export let autoPlay = false;
    export let slideTransitionDelay: number = 0;

    let videoElement: HTMLVideoElement;
    let drawCanvas: HTMLCanvasElement;
    let chromaCanvas: HTMLCanvasElement;

    function goToNextSlide() {
        setTimeout(() => {
            nextSlide();
        }, slideTransitionDelay);
    }

    function chromaKey() {
        const drawContext = drawCanvas.getContext('2d');
        const chromaContext = chromaCanvas.getContext('2d');
        if (!drawContext || !chromaContext) {
            return;
        }

        let width: number, height: number;
        let contextWidth: number, contextHeight: number;

        const initializeTimer = () => {
            if (!videoElement || videoElement.videoWidth === 0) {
                setTimeout(initializeTimer, 50);
                return;
            }

            width = videoElement.videoWidth;
            height = videoElement.videoHeight;

            contextWidth = drawCanvas.width;
            contextHeight = drawCanvas.height;

            timerCallback();
        };

        const timerCallback = () => {
            if (!videoElement || videoElement.paused || videoElement.ended) {
                return;
            }
            computeFrame();
            setTimeout(timerCallback, 10);
        };

        const computeFrame = () => {
            drawContext.drawImage(
                videoElement,
                0,
                0,
                width,
                height,
                0,
                0,
                contextWidth,
                contextHeight
            );
            let frame = drawContext.getImageData(
                0,
                0,
                contextWidth,
                contextHeight
            );
            let length = frame.data.length / 4;

            for (let i = 0; i < length; i++) {
                let r = frame.data[i * 4];
                let g = frame.data[i * 4 + 1];
                let b = frame.data[i * 4 + 2];
                if (g > 80 && g > b + r - 50) {
                    frame.data[i * 4 + 3] = 0;
                    frame.data[i * 4] = 0;
                    frame.data[i * 4 + 1] = 0;
                    frame.data[i * 4 + 2] = 0;
                }
            }

            chromaContext.putImageData(frame, 0, 0);
        };

        initializeTimer();
    }

    $: playing = [
        SlideDisplayTag.LAST_SLIDE_PLAYING,
        SlideDisplayTag.FIRST_SLIDE_PLAYING,
        SlideDisplayTag.PLAYING,
    ].includes(slideState.tag);

    onMount(() => {
        if (autoPlay && !playing) {
            play();
        }
    });
</script>

{#if playing && !slideState.currentSlide.speakerVideoPath}
    <audio
        src={slideState.currentSlide.audioPath}
        autoplay
        on:ended={goToNextSlide}
    />
{/if}

{#if slideState.currentSlide.speakerVideoPath && playing}
    {#key slideState.currentSlide.speakerVideoPath}
        <video
            bind:this={videoElement}
            autoplay
            id="speaker-video"
            src={slideState.currentSlide.speakerVideoPath}
            on:ended={goToNextSlide}
            on:play={chromaKey}
            style="position: absolute; top: 0; left: 0; width: 30%; visibility: hidden;"
        />
    {/key}
{/if}

<canvas
    bind:this={drawCanvas}
    id="draw-canvas"
    width="512"
    height="512"
    style="display: none;"
/>
<canvas
    bind:this={chromaCanvas}
    id="chroma-canvas"
    width="512"
    height="512"
    style="position: absolute; right: 10vw; bottom: 0; width: 50vh;"
/>
