import { api } from '../../common/api';
import { firstValueFrom } from 'rxjs';
import { loginStatus$ } from '../store/loginV2';
import { LoginStatus } from '../../common/types';

const doUploadPersonData = api.path('/api/personData').method('post').create();

export async function uploadPersonData(
    imageData: string | undefined,
    audioData: string | undefined
): Promise<{ imageFile: string | undefined; audioFile: string | undefined }> {
    if (imageData && audioData) {
        const loginStatus = await firstValueFrom(loginStatus$);
        if (loginStatus.status !== LoginStatus.LOGGED_IN) {
            throw new Error('need to be logged in to load suggestions');
        }

        const data = (
            await doUploadPersonData(
                { imageData, audioData },
                { headers: { authorization: `Bearer ${loginStatus.token}` } }
            )
        ).data;

        console.log(data, typeof data);
        return data;
    } else {
        return { imageFile: undefined, audioFile: undefined };
    }
}
