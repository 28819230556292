<script lang="ts">
    export let retry: () => void;
</script>

<div class="wrapper">
    Generation has failed<br />
    <div class="error">Technical Error</div>
    <button on:click={retry}>try again?</button>
</div>

<style>
    .wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0.5rem;
    }
    .error {
        color: red;
        font-weight: bold;
    }
</style>
