<script lang="ts">
    import { onMount, createEventDispatcher } from 'svelte';
    import { _ } from 'svelte-i18n';

    const newRecording = createEventDispatcher<{
        newRecording: { encodedImage: string; encodedAudio: string };
    }>();
    const imageWidth = 1024;

    let audioElement: HTMLAudioElement;
    let videoElement: HTMLVideoElement;
    let canvasElement: HTMLCanvasElement;

    let stream: MediaStream;
    let mediaRecorder: MediaRecorder;
    let audioChunks: BlobPart[] = [];

    let encodedImage: string | null = null;
    let encodedAudio: string | null = null;

    let recordDialogElement: HTMLDialogElement;
    let isRecordingAudio = false;
    let modalContent:
        | 'imageRecording'
        | 'imageApproval'
        | 'audioRecording'
        | 'audioApproval' = 'imageRecording';

    export let personSelected: boolean = false;

    $: if (videoElement && stream) {
        startWebcam();
    }

    onMount(async () => {});

    export async function startCapture() {
        stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
        });

        modalContent = 'imageRecording';
        recordDialogElement.showModal();
    }

    function startWebcam() {
        videoElement.srcObject = stream;
        showCameraImage();
    }

    function showCameraImage() {
        const context = canvasElement.getContext('2d');

        if (!context) {
            throw new Error('Could not get 2d context from canvas');
        }
        const draw = function () {
            try {
                context.drawImage(
                    videoElement,
                    (videoElement.videoWidth - videoElement.videoHeight) / 2,
                    0,
                    videoElement.videoHeight,
                    videoElement.videoHeight,
                    0,
                    0,
                    canvasElement.width,
                    canvasElement.height
                );

                requestAnimationFrame(draw);
            } catch (e) {
                // to be ignored
            }
        };

        requestAnimationFrame(draw);
    }

    function takeSnapshot() {
        canvasElement.toBlob(async (blob) => {
            encodedImage = await blobToBase64(blob!!);
            modalContent = 'imageApproval';
        });
    }

    function acceptImage() {
        modalContent = 'audioRecording';
        setupAudioRecording(stream);
    }

    function retakeImage() {
        modalContent = 'imageRecording';
    }

    function setupAudioRecording(stream: MediaStream) {
        let audioTracks = stream.getAudioTracks();
        let audioOnlyStream = new MediaStream(audioTracks);
        mediaRecorder = new MediaRecorder(audioOnlyStream);
        mediaRecorder.ondataavailable = (event) => {
            audioChunks.push(event.data);
        };
        mediaRecorder.onstop = async () => {
            const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
            encodedAudio = await blobToBase64(audioBlob!!);
            modalContent = 'audioApproval';

            console.log(audioElement);
            audioElement?.play();
            setTimeout(() => {
                audioElement?.pause();
            }, 10);
        };
    }

    function startAudioRecording() {
        audioChunks = [];
        mediaRecorder.start();
        isRecordingAudio = true;
    }

    function stopAudioRecording() {
        mediaRecorder.stop();
        isRecordingAudio = false;
    }

    function acceptAudio() {
        audioElement?.pause();
        recordDialogElement.close();
        stopAllTracks();

        personSelected = true;
        newRecording('newRecording', {
            encodedImage: encodedImage!!,
            encodedAudio: encodedAudio!!,
        });
    }

    function retakeAudio() {
        audioElement?.pause();

        modalContent = 'audioRecording';
    }

    function reset() {
        personSelected = false;
        encodedImage = null;
        encodedAudio = null;
        recordDialogElement.close();
        stopAllTracks();
    }

    function stopAllTracks() {
        stream.getTracks().forEach((track) => track.stop());
    }

    async function blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(blob);
        });
    }
</script>

<dialog bind:this={recordDialogElement} class="modal">
    {#if modalContent === 'imageRecording'}
        <h1>{$_('record.takeSnapshotHeader')}</h1>

        <div class="modal-body">
            <!-- svelte-ignore a11y-media-has-caption since there are simply no captions -->
            <video
                class="video"
                bind:this={videoElement}
                width={imageWidth}
                autoplay
                playsinline
                muted
            />
            <canvas
                class="camera-feed snapshot-image"
                bind:this={canvasElement}
                width={imageWidth}
                height={imageWidth}
            />
        </div>

        <div>
            <button on:click={takeSnapshot}>
                {$_('record.takeSnapshot')}
            </button>
            <button on:click={reset}>
                {$_('record.cancel')}
            </button>
        </div>
    {:else if modalContent === 'imageApproval'}
        <h1>{$_('record.acceptSnapshotHeader')}</h1>

        <div class="modal-body">
            <img
                class="snapshot-image"
                src={encodedImage}
                alt={$_('record.portrait')}
            />
        </div>
        <div>
            <button on:click={acceptImage}>
                {$_('record.acceptSnapshot')}
            </button>
            <button on:click={retakeImage}>
                {$_('record.retakeImage')}
            </button>
            <button on:click={reset}>
                {$_('record.cancel')}
            </button>
        </div>
    {:else if modalContent === 'audioRecording'}
        <h1>{$_('record.audioRecordingHeader')}</h1>

        <div class="modal-body">
            <p>{$_('record.readText')}</p>
        </div>

        <div>
            <button on:click={startAudioRecording} disabled={isRecordingAudio}>
                {$_('record.startAudioRecording')}
            </button>
            <button
                on:click={stopAudioRecording}
                disabled={!isRecordingAudio}
                class="recording-animation"
            >
                {$_('record.stopAudioRecording')}
            </button>
            <button on:click={reset}>
                {$_('record.cancel')}
            </button>
        </div>
    {:else if modalContent === 'audioApproval'}
        <h1>{$_('record.acceptAudioHeader')}</h1>

        <div class="modal-body">
            <audio
                class="audio"
                bind:this={audioElement}
                controls
                preload="auto"
                src={encodedAudio}
            />
        </div>

        <div>
            <button on:click={acceptAudio}>
                {$_('record.acceptAudioRecording')}
            </button>
            <button on:click={retakeAudio}>
                {$_('record.retakeAudioRecording')}
            </button>
            <button on:click={reset}>
                {$_('record.cancel')}
            </button>
        </div>
    {/if}
</dialog>

<style>
    .modal {
        width: 70vh;
        height: 70vh;
        background-color: white;
        border: 0.2rem solid var(--bs-light);
        padding: 2vw;
        border-radius: 0.5rem;
        box-shadow: 0 0 0.5rem 0.5rem rgba(255, 255, 255, 0.1);
        overflow: hidden;
        flex-direction: column;
    }

    .modal[open] {
        display: flex;
    }

    .modal-body {
        height: 90%;
        /* center everything */
        display: flex;
        justify-content: center;
    }

    .modal::backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
    }

    @media (max-width: 400px) {
        .modal {
            margin: 0;
            width: 100%;
            box-sizing: border-box;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 0;
            border: none;
        }
    }

    .video {
        width: 0;
        height: 0;
    }

    .snapshot-image {
        width: auto;
        height: 100%;
        transform: scaleX(-1);
    }

    .camera-feed {
        width: auto;
        height: 100%;
    }

    .audio {
        margin-top: 40%;
    }

    .recording-animation {
        animation: pulse 1s infinite;
    }
</style>
