<script lang="ts">
    import tngLogo from '../../../assets/tng.svg';
    import Slide from './slides/Slide.svelte';
    import type { SlideDisplayState } from '../../logic/slideDisplay';
    import { SlideDisplayTag } from '../../logic/slideDisplay';

    export let slide: SlideDisplayState;

    $: first =
        slide.tag === SlideDisplayTag.FIRST_SLIDE_PLAYING ||
        slide.tag === SlideDisplayTag.FIRST_SLIDE_PAUSED;
    $: last =
        slide.tag === SlideDisplayTag.LAST_SLIDE_FINISHED ||
        slide.tag === SlideDisplayTag.LAST_SLIDE_PAUSED ||
        slide.tag === SlideDisplayTag.LAST_SLIDE_PLAYING;
    $: done = slide.tag === SlideDisplayTag.LAST_SLIDE_FINISHED;
</script>

<div class="wrapper">
    <div
        class="background"
        style="background-image: url('{slide.presentation.initialSlide
            .imagePath}'); {!first
            ? 'filter: blur(0.5rem) brightness(0.5); transform: scale(1.1);'
            : 'filter: brightness(0.5);'} transition: transform 1.0s, filter 1.0s;"
    />
    {#if last}
        <div
            class="background fade-in dark {done ? 'blur' : ''}"
            style="background-image: url('{slide.presentation.finalSlide
                .imagePath}');"
        />
    {/if}
    {#if slide}
        {#key slide.slideIndex}
            <Slide
                state={slide.tag}
                slide={slide.currentSlide}
                index={slide.slideIndex}
            />
        {/key}
    {/if}
    <div class="footer">
        <img src={tngLogo} alt="TNG Logo" class="logo" />
        <span class="footer-title">
            {slide.presentation.initialSlide.text}
        </span>
        <span class="warning">AI-generated Content </span>
    </div>
</div>

<style>
    .wrapper {
        position: relative;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        z-index: 5;
    }

    .logo {
        width: 7dvw;
    }

    .footer-title {
        color: white;
        font-size: 1.5dvw;
    }

    .warning {
        color: white;
        font-size: 1.5dvw;
        flex-grow: 1;
        text-align: right;
    }

    .background {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }

    .fade-in {
        animation: fade-in 1s ease-in-out;
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .dark {
        filter: brightness(0.5);
    }

    .blur {
        filter: blur(0.5dvw) brightness(0.5);
        transform: scale(1.1);
        transition: filter 1s, transform 1s;
    }
</style>
