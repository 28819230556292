<script lang="ts">
    import { Card } from 'sveltestrap';
    import LanguageSwitcher from './LanguageSwitcher.svelte';
</script>

<div class="info">
    <Card class="d-flex flex-row p-2 align-items-center gap-2">
        <LanguageSwitcher />
    </Card>
</div>

<style>
    .info {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
</style>
