import aiSlidesAudio from '../../../kiosk/assets/audio/en/ai-slides.mp3';
import gpt4Audio from '../../../kiosk/assets/audio/en/gpt4.mp3';
import stableDiffusionAudio from '../../../kiosk/assets/audio/en/stableDiffusion.mp3';
import pollyAudio from '../../../kiosk/assets/audio/en/polly.mp3';
import azureAudio from '../../../kiosk/assets/audio/en/azure.mp3';
import type { LocalizationSettings } from './types';
import { PresentationTone } from '../../types';

const en: LocalizationSettings = {
    key: 'en',
    languageLabel: 'English',
    languageNameForGeneration: 'English',
    switchLabel: 'Language settings',
    rotatePrompt: 'Please turn your device to view the presentation',
    overview: {
        title: 'AI Slides',
        tagline: 'AI automatically creates presentations',
        newPresentation: 'create your own presentation',
    },
    newPresentation: {
        createPresentationTitle: 'Create a presentation using AI',
        presentationTitle: 'What should the topic be?',
        toneErnest: 'serious',
        toneFunny: 'funny',
        createButton: 'create now',
        presentationStyle: 'style',
        topic: 'about ‘{topic}’',
        backToLastPresentation: 'go back to the presentations',
        backToOverview: 'go back to the overview',
    },
    newStory: {
        createStory: 'Which story should I tell?',
        storyReady: 'Done! Would you like me to read you the story?',
        creationFailed: 'Oops, something went wrong.',
        titleLabel: 'Your title',
        submitButtonText: "Let's go",
        readOutButtonText: 'Read out',
        retryButtonText: 'Try again',
    },
    newDallicParty: {
        initialPrompt: 'Initial prompt',
        changePrompt: 'Change prompt',
        numberOfIterations: 'Number of iterations',
    },
    presentationReady: {
        goToPresentation: 'view presentation',
    },
    generationFailed: {
        headline: 'Sorry, the creation process failed',
        spicyHeadline:
            'The given topic was detected to be potentially inappropriate',
        retryPrompt: 'Please try again with a different topic',
        quotaExceeded: 'The limit for the number of presentations was reached',
    },
    presentation: {
        jumpToOverview: 'back to overview',
        jumpToNext: 'jump to the next presentation',
        createYourOwn: 'create your own presentation',
        confirmTitle: 'presentation completed',
        confirmNext: 'to the next presentation',
        replay: 'play again',
        confirmCreateOwn: 'Do you want to create your own presentation?',
        confirmCreateOwnAction: 'create my own presentation',
        declineCreateOwnAction: 'continue viewing this presentation',
    },
    story: {
        childBookTitle: 'The automatic story teller',
        darkTalesTitle: 'Dark Tales',
        christmasTitle: 'The mechanical Santa',
        hearAgainButtonText: 'Read once again',
        backButtonText: 'Back to the overview',
        playButtonText: 'Play',
    },
    info: {
        aiSlides: {
            title: 'AI Slides',
            text: [
                'AI-Slides, developed by TNG Technology Consulting GmbH, is an AI-based tool that transforms a user’s input into a complete presentation. This process involves multiple AI technologies working together. The process starts when a user provides a presentation title and sets the desired tone (funny or earnest). This prompt is then passed to [GPT-4](gpt4), an advanced text generating AI, which provides headlines for each slide, the presenter’s spoken text, and a description of a fitting image for each slide.',
                'Following this, the created presenter’s text undergoes a transformation. It is passed on to either [Amazon Polly](polly) or [Azure Text-to-Speech](azure), AI tools that convert the written text into a spoken audio track, almost like a virtual narrator for the show. This is based on the user’s choice and both of them utilize advanced text-to-speech technology to produce lifelike, human-like speech.',
                'Alongside this, the image descriptions given by [GPT-4](gpt4) are utilized as instructions for [Stable Diffusion](stableDiffusion), an AI-based image generator. It interprets these instructions to generate appropriate images for each slide of the presentation.',
                'Once everything is done ‒ the audio tracks, the images, and the slide text ‒ they are all integrated together to form a finished, AI-composed presentation. This can then be viewed in a web browser, offering a user-friendly way to quickly generate presentations with just a prompt.',
            ],
            audioFile: aiSlidesAudio,
        },
        gpt4: {
            title: 'GPT-4',
            text: [
                'GPT-4, shorthand for Generative Pretrained Transformer 4, is an artificial intelligence system that’s designed to create text. This AI uses a network of algorithms, similar to how the human brain uses a network of neurons, to evaluate, understand, and generate text based on a given prompt. It does this by finding patterns and relationships between words, phrases, and sentences in a massive database of diverse internet text it was trained on. ',
                'GPT-4 generates responses to a prompt by using what it has learned to guess the next word in a sentence. This is similar to how we humans might play a game where you have to guess the next word in a sentence. It predicts what comes next by calculating the probability of which word often comes after the last word in the prompt or sentence.',
                'What makes GPT-4 super impressive is that not only it can write grammatically correct text, but it can also guess the context of what it is writing about. It can create detailed responses that can demonstrate understanding of the topic, exhibit creativity, and even express opinion, making it seem like the text was written by a human.',
            ],
            audioFile: gpt4Audio,
        },
        stableDiffusion: {
            title: 'Stable Diffusion',
            text: [
                'Stable Diffusion, an AI-based image generator, operates by a similar method to [text generators](gpt4) but works with visual data instead of linguistic data. The primary way it generates images from a prompt is by turning the prompt into an exploration journey from noise to the final output we see as images.',
                'You can start by imagining the image generation process as filling an empty canvas with ideas from a direct prompt given. However, instead of starting from nothing and adding details directly, Stable Diffusion starts with a canvas full of random noise, or ‘fuzz’, and gradually refines this into a coherent image.',
                'The improvement happens in tiny steps. At each step, the AI revises a small part of the image based on two pieces of information: the overall goal it is aiming for (which is related to the prompt), and the need to make the resulting picture look as realistic as possible. This process is repeated, often hundreds of times, until the noise is transformed into an image that both looks realistic and matches the original prompt. The AI learning has happened in a way similar to that of GPT-4: it has seen lots of images and understood how different details and structures fit together to form realistic images, and it uses that knowledge to interpret and respond to the prompt.',
            ],
            audioFile: stableDiffusionAudio,
        },
        polly: {
            title: 'Polly',
            text: [
                'Amazon Polly, a service provided by Amazon Web Services (AWS), is a technology that converts text into lifelike speech. Think of it as an excellent reader who can read out written text in a human-like voice. The aim is to create an engaging user experience with applications like news readers, language translators, and voice assistants.',
                'At its core, Amazon Polly uses advanced deep learning technologies. It starts by analyzing the input text to identify words, punctuation, and the structure of the sentences ― this step is called text analysis. Once the text is broken down in this way, Amazon Polly then generates the speech that corresponds to the text. This process is called synthesizing speech or text-to-speech (TTS).',
                'What makes Amazon Polly stand out is its ability to deliver high-quality voice output in many different languages and dialects. It can also execute prosody elements, meaning it can modulate the pitch, tempo, and volume of the speech for a more natural and expressive voice output. For example, it can make its voice sound question-like when encountering a question mark, or express excitement when reading an exclamation point. It even breathes with appropriate pauses between sentences for an even more realistic speech result.',
            ],
            audioFile: pollyAudio,
        },
        azure: {
            title: 'Azure TTS',
            text: [
                'Azure Text to Speech, a service provided by Microsoft Azure, is an advanced technology that turns written text into spoken words. It is like a virtual narrator that reads out the text in a way that sounds quite human. Azure Text to Speech is designed to make user interactions with applications like eBooks, assistive technologies, or navigation systems more interactive and intuitive.',
                'The way Azure Text to Speech works is essentially hinged on two primary steps: text analysis and speech synthesis. In the first step, the system takes the input text and breaks it down into smaller understandable units, detects the language, identifies the punctuation marks and divides the text into meaningful phrases. In the second step, using what we called a ‘text-to-speech’ process, Azure uses these analyzed pieces to generate spoken words that match the written text.',
                'What make Azure Text to Speech special are features like ‘Neural Voices’ that use deep neural networks to produce human-like voices and ‘Custom Voice’, a feature which lets you create a unique voice for your brand. It can express emotions and intents in the speech, modulate its tone, tempo and volume, giving natural intonations to the synthesized speech. Plus, it can do all this in various languages and dialects, making it a highly versatile tool.',
            ],
            audioFile: azureAudio,
        },
    },
    record: {
        startRecording: 'Start recording',
        portrait: 'Portrait image',
        takeSnapshotHeader: 'Take a snapshot',
        takeSnapshot: 'Take snapshot',
        acceptSnapshotHeader: 'Accept snapshot',
        acceptSnapshot: 'Accept snapshot',
        retakeImage: 'Retake image',
        audioRecordingHeader: 'Record audio',
        readText:
            'Early in the morning, the city wakes up to the sound of busy streets and cheerful birds. People rush to their work, sipping coffee and greeting each other with a smile. In the park, dogs run freely, chasing after their favorite toys. The air is fresh, filled with the aroma of blooming flowers and freshly baked bread from the nearby bakery. Life in the city is vibrant, with every corner offering a new story to discover.',
        startAudioRecording: 'Start audio recording',
        stopAudioRecording: 'Stop audio recording',
        acceptAudioHeader: 'Accept audio recording',
        acceptAudioRecording: 'Accept audio recording',
        retakeAudioRecording: 'Retake audio recording',
        cancel: 'Cancel',
    },
    upload: {
        dragDropImage: 'Drag and drop an image here, or click to select',
        dragDropAudio: 'Drag and drop an audio file here, or click to select',
        altImage: 'Portrait',
        audioNotSupported: 'Your browser does not support audio',
    },
    readInfo: 'read',
    pauseReading: 'pause',
    generatorMessages: {
        [PresentationTone.FUNNY]: {
            initial: 'Firing up the AI brain for some slide deck magic.',
            messages: [
                "Feeding data into the AI, let's see what it can cook up.",
                'Pulling topics out of the virtual hat for your slide deck.',
                'Judging the relevance of topics, not all ideas are gold.',
                "Flexing the AI's vocabulary muscles for text generation.",
                'Crafting your slide deck outline, like a fine artisan.',
                'Playing around with colors, every slide deck needs a little splash.',
                "First draft done - not perfect, but it's a start.",
                'Turning each slide into a mini masterpiece.',
                'Flipping through the dictionary for the perfect words.',
                'Summoning the power of Picasso for some image placing.',
                'Unleashing the grammar police, courtesy of deep learning.',
                'Running a text-to-common-sense test, keeping it real.',
                'Curating slide order for a jaw-dropping narrative.',
                'Fixing visuals like a pro - clarity is king.',
                'Creating infographics, making numbers fun again.',
                'Bridging slides with smooth transitions, just like a pro skater.',
                'Thinking up a catchy intro, first impressions matter.',
                'Giving the text a readability makeover, out with the complex!',
                "Weaving in audience attention-grabbers, because who doesn't love interaction?",
                'Optimizing slide deck for big screens, small screens, and screens in-between.',
                'Polishing the outline, because we’re all about that shape.',
                'Spinning words into magic, because detail is key.',
                'Jazzing up the text design, legibility never looked so good.',
                'Designing closing slide, the grand finale.',
                'Calling in the virtual inspector gadget for a final visual check.',
                'Playing Tetris with text and visuals, until it fits just right.',
                'Bringing in insightful nuggets, straight from the AI oven.',
                "Scanning sentiment, ensuring the slide deck doesn't hurt feelings.",
                'Making sure our slides are on the same page.',
                'Turning the visual appeal knob up to 11.',
                'Locking in the color scheme, because who decides on the first try?',
                'Double-checking image resolutions, no room for pixel play.',
                'Invoking the power of language processing, hello smart text.',
                'Cooking up a slide deck title that would make Spielberg jealous.',
                'Checking infographic legibility, making sure even grandpa can read it.',
                'Shuffling text sizes and fonts, for the love of style.',
                'Breathing context into images, every picture tells a story.',
                'Timing slides to perfection, not too fast, not too slow.',
                'Trimming the slide deck size, keeping it travel-friendly.',
                'Sit back, final review in progress, almost there...',
                'Testing slide deck structure for a smooth narrative flow.',
                'One last sprinkling of design magic.',
                'Performing last minute detective work for coherence, no mystery unsolved.',
                'Polishing the slide deck for the grand reveal.',
                'Distilling the essence of the slide deck into a killer summary.',
                'Crossing the finish line on design and content.',
                'Exporting your sparkly slide deck, in all its glory.',
                'Tada! Your slide deck is ready for its standing ovation.',
                'Putting a bow on the AI’s work, slide deck check.',
                'Who’s up for a victory dance? Slide deck creation, check.',
                'Shutting down the AI engine, it’s earned a good sleep.',
            ],
        },
        [PresentationTone.ERNEST]: {
            initial: 'Initialising AI system for slide deck creation.',
            messages: [
                'Preprocessing raw data for insights generation.',
                'Creating a list of potential topics for the slide deck.',
                'Validating the relevance of the generated topics.',
                'Initiating generation of contextual text for each topic.',
                'Creating a slide deck outline based on topic analysis.',
                'Designing color scheme for the slide deck.',
                'Creating the first draft of the slide deck design.',
                'Initiating the design of individual slides.',
                'Generating text for individual slides.',
                'Incorporating images and visual elements into slides.',
                'Engaging deep learning algorithms for proofreading text.',
                'Conducting a coherence check on the generated text.',
                'Arranging slides based on the information hierarchy.',
                'Fine-tuning visual elements for optimal clarity.',
                'Generating infographic elements for data representation.',
                'Establishing links between slides for smooth transitions.',
                'Initiating the introduction slide creation.',
                'Enhancing the readability of the slide deck text.',
                'Incorporating audience engagement elements.',
                'Optimizing slide deck for mobile and desktop viewing.',
                'Fine-tuning the outline of the slide deck.',
                'Generating grammatically correct and insightful sentences.',
                'Refining the design of the text for better visibility.',
                'Creating a professional and aesthetically pleasing closing slide.',
                'Running a final check on all visual elements in the slide deck.',
                'Ensuring text and visuals are well-aligned in each slide.',
                'Incorporating AI generated insights into relevant slides.',
                'Deploying machine learning for sentiment analysis of text.',
                'Inspecting the consistency between slides.',
                'Boosting the visual appeal of the slide deck.',
                'Finalizing the color scheme of the slide deck.',
                'Ensuring resolution of all images is optimal.',
                'Deploying natural language processing to enhance the textual content.',
                'Creating a compelling slide deck title.',
                'Inspecting the readability of infographic elements.',
                'Modifying text size and fonts for better legibility.',
                'Adding context to images and visual elements.',
                'Ensuring all slides meet the designated presentation time.',
                'Optimizing overall slide deck size for easy sharing.',
                'Conducting a final review for potential improvements.',
                'Analyzing the structure of the slide deck for logic flow.',
                'Finalizing the content and design of the slide deck.',
                'Running the last coherence check on the slide deck.',
                'Polishing the slide deck for final delivery.',
                'Creating a summary of the slide deck content.',
                'Completing the design and content creation process.',
                'Exporting the finished slide deck in the preferred format.',
                'Slide deck is ready for review and feedback.',
                'AI system is finalizing the slide deck.',
                'The AI-based slide deck creation is complete.',
                'Initiating the deactivation of the slide deck creation algorithm.',
                'AI system shut down after successful creation of slide deck.',
            ],
        },
    },
    keyboardLayout: {
        default: [
            '1 2 3 4 5 6 7 8 9 0 - = {bksp}',
            'q w e r t y u i o p [ ] !',
            'a s d f g h j k l ; ?',
            '{shift} z x c v b n m , . / {shift}',
            '@ {space}',
        ],
        shift: [
            '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
            'Q W E R T Y U I O P { } !',
            'A S D F G H J K L : ?',
            '{shift} Z X C V B N M < > ? {shift}',
            '@ {space}',
        ],
    },
    keyboardLabels: {
        '{bksp}': '⌫',
        '{shift}': '⇧ shift',
        '{space}': 'space',
    },
    landingpage: {
        login: {
            login: 'Login',
            logout: 'Logout',
            username: 'Username',
            password: 'Password',
            changePassword: 'Change Password',
            newPassword: 'New Password',
            repeatNewPassword: 'Repeat',
            close: 'Close',
        },
        menu: {
            home: 'Home',
            suggestTopic: 'Suggest a Topic',
            reviewSuggestions: 'Review Suggestions',
            newPresentation: 'New Presentation',
            newStory: 'New Children’s Book',
            newDarkTale: 'New Dark Tale',
            newChristmasTale: 'New Christmas Story',
            newDallicParty: 'New Dallic Party',
        },
        footer: {
            imprint: 'Imprint',
            privacy: 'Privacy Statement',
            language: 'Change language',
        },
        presentations: {
            favorite: 'favorite',
            unfavorite: 'remove from favorites',
            delete: 'delete',
            unpublish: 'unpublish',
            publish: 'Publish',
            failedToDelete: 'failed to delete presentation',
            failedToUnpublish: 'failed to unpublish presentation',
        },
        suggestTopic: {
            title: 'Suggest a presentation topic',
            yourSuggestion: 'your suggestion',
            submitSuggestion: 'submit suggestion',
            suggestionSubmitted: 'Your suggestion has been submitted',
            suggestAnother: 'make another suggestion',
            submissionFailed: 'Sorry, we could not save your submission',
            retry: 'try again',
        },
        manageSuggestions: {
            title: 'Manage suggestions',
            delete: 'delete suggestion',
        },
        newPresentation: {
            title: 'New Presentation',
            topic: 'Topic',
            generateTopic: 'Generate topic',
            useSuggestion: 'Use a suggestion',
            numberOfSlides: 'Number of slides',
            narrationStyle: 'Narration style',
            personality: 'Personality',
            customPersonality: 'Custom Personality',
            yourPersonality: 'You',
            showPersonData: 'Show Person Data',
            hidePersonData: 'Hide Person Data',
            person: {
                name: 'Name',
                description: 'Person Description',
                speaker: 'Speaker',
                imageDescription: 'Image Description',
                fillBlanks: 'Fill in blanks',
                clear: 'Clear Person',
                recording: 'Recording',
                noRecording: 'No recordning yet',
            },
            imageGenerator: 'Image Generator',
            videoGenerator: 'Video Generator',
            textGenerator: 'Text Generator',
            coverSlideVideo: 'Cover Slide Video',
            createCoverSlideVideo: 'Create cover slide video',
            content: 'Content',
            moderateContent: 'Moderate content',
            promotion: 'Promotion',
            noPromotion: 'None',
            createPresentation: 'Create Presentation',
        },
    },
};
export default en;
