import type { PresentationJson } from './presentationJson';
import {
    type FirstSlidePaused,
    type SlideDisplayState,
    SlideDisplayTag,
} from './slideDisplay';
import { writable } from 'svelte/store';
import { loadPresentationJson } from './presentationJson';

export type LoadingPresentation = {
    tag: 'LOADING_PRESENTATION';
};
export type LoadingPresentationError = {
    tag: 'LOADING_PRESENTATION_ERROR';
    error: unknown;
};

export type PresentationLogicState =
    | LoadingPresentation
    | LoadingPresentationError
    | SlideDisplayState;

export function isLoaded(
    state: PresentationLogicState
): state is SlideDisplayState {
    return !isLoading(state) && !isLoadingError(state);
}

export function isLoading(
    state: PresentationLogicState
): state is LoadingPresentation {
    return state.tag === 'LOADING_PRESENTATION';
}

export function isLoadingError(
    state: PresentationLogicState
): state is LoadingPresentationError {
    return state.tag === 'LOADING_PRESENTATION_ERROR';
}
export function getPresentationLogic(presentationURL: string) {
    function getSlides(state: SlideDisplayState) {
        return [
            state.presentation.initialSlide,
            ...state.presentation.slides,
            state.presentation.finalSlide,
        ];
    }

    function getInitialState(state: PresentationJson): FirstSlidePaused {
        return {
            tag: SlideDisplayTag.FIRST_SLIDE_PAUSED,
            currentSlide: state.initialSlide,
            presentation: state,
            slideIndex: 0,
        };
    }

    const slideState = writable<PresentationLogicState>({
        tag: 'LOADING_PRESENTATION',
    });

    void loadPresentationJson(presentationURL).then(
        (presentationJson) => {
            slideState.set(getInitialState(presentationJson));
        },
        (error) => {
            slideState.set({
                tag: 'LOADING_PRESENTATION_ERROR',
                error,
            });
        }
    );

    function nextSlide() {
        slideState.update((state) => {
            if (!isLoaded(state)) {
                return state;
            }
            const allSlides = getSlides(state);
            const nextSlide = Math.min(
                state.slideIndex + 1,
                allSlides.length - 1
            );
            switch (state.tag) {
                case SlideDisplayTag.LAST_SLIDE_PAUSED:
                case SlideDisplayTag.LAST_SLIDE_FINISHED:
                case SlideDisplayTag.LAST_SLIDE_PLAYING:
                    return {
                        ...state,
                        tag: SlideDisplayTag.LAST_SLIDE_FINISHED,
                    };
                case SlideDisplayTag.FIRST_SLIDE_PAUSED:
                case SlideDisplayTag.PAUSED:
                    return {
                        ...state,
                        tag:
                            nextSlide >= allSlides.length - 1
                                ? SlideDisplayTag.LAST_SLIDE_PAUSED
                                : SlideDisplayTag.PAUSED,
                        currentSlide: allSlides[nextSlide],
                        slideIndex: nextSlide,
                    };
                case SlideDisplayTag.FIRST_SLIDE_PLAYING:
                case SlideDisplayTag.PLAYING:
                    return {
                        ...state,
                        tag:
                            nextSlide >= allSlides.length - 1
                                ? SlideDisplayTag.LAST_SLIDE_PLAYING
                                : SlideDisplayTag.PLAYING,
                        currentSlide: allSlides[nextSlide],
                        slideIndex: nextSlide,
                    };
                default:
                    return state;
            }
        });
    }

    function previousSlide() {
        slideState.update((state) => {
            if (!isLoaded(state)) {
                return state;
            }
            const allSlides = getSlides(state);
            const nextSlide = Math.max(state.slideIndex - 1, 0);
            switch (state.tag) {
                case SlideDisplayTag.FIRST_SLIDE_PAUSED:
                case SlideDisplayTag.FIRST_SLIDE_PLAYING:
                    return {
                        ...state,
                        tag: SlideDisplayTag.FIRST_SLIDE_PAUSED,
                    };
                case SlideDisplayTag.LAST_SLIDE_PAUSED:
                case SlideDisplayTag.PAUSED:
                    return {
                        ...state,
                        tag:
                            nextSlide <= 0
                                ? SlideDisplayTag.FIRST_SLIDE_PAUSED
                                : SlideDisplayTag.PAUSED,
                        currentSlide: allSlides[nextSlide],
                        slideIndex: nextSlide,
                    };
                case SlideDisplayTag.LAST_SLIDE_FINISHED:
                    return {
                        ...state,
                        tag: SlideDisplayTag.LAST_SLIDE_PAUSED,
                    };
                case SlideDisplayTag.LAST_SLIDE_PLAYING:
                case SlideDisplayTag.PLAYING:
                    return {
                        ...state,
                        tag:
                            nextSlide <= 0
                                ? SlideDisplayTag.FIRST_SLIDE_PLAYING
                                : SlideDisplayTag.PLAYING,
                        currentSlide: allSlides[nextSlide],
                        slideIndex: nextSlide,
                    };
                default:
                    return state;
            }
        });
    }

    function pause() {
        slideState.update((state) => {
            if (!isLoaded(state)) {
                return state;
            }
            switch (state.tag) {
                case SlideDisplayTag.FIRST_SLIDE_PLAYING:
                    return {
                        ...state,
                        tag: SlideDisplayTag.FIRST_SLIDE_PAUSED,
                    };
                case SlideDisplayTag.PLAYING:
                    return {
                        ...state,
                        tag: SlideDisplayTag.PAUSED,
                    };
                case SlideDisplayTag.LAST_SLIDE_PLAYING:
                    return {
                        ...state,
                        tag: SlideDisplayTag.LAST_SLIDE_PAUSED,
                    };
                default:
                    return state;
            }
        });
    }

    function play() {
        slideState.update((state) => {
            if (!isLoaded(state)) {
                return state;
            }
            switch (state.tag) {
                case SlideDisplayTag.FIRST_SLIDE_PAUSED:
                    return {
                        ...state,
                        tag: SlideDisplayTag.FIRST_SLIDE_PLAYING,
                    };
                case SlideDisplayTag.PAUSED:
                    return {
                        ...state,
                        tag: SlideDisplayTag.PLAYING,
                    };
                case SlideDisplayTag.LAST_SLIDE_PAUSED:
                case SlideDisplayTag.LAST_SLIDE_FINISHED:
                    return {
                        ...state,
                        tag: SlideDisplayTag.LAST_SLIDE_PLAYING,
                    };
                default:
                    return state;
            }
        });
    }

    function restart() {
        slideState.update((state) => {
            if (!isLoaded(state)) {
                return state;
            }
            return getInitialState(state.presentation);
        });
    }

    return {
        slideState,
        nextSlide,
        previousSlide,
        pause,
        play,
        restart,
    };
}
