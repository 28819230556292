<script lang="ts">
    import type { SlideDisplayState } from '../../common/renderPresentation/logic/slideDisplay';
    import { SlideDisplayTag } from '../../common/renderPresentation/logic/slideDisplay';
    import playButton from '../assets/play-button.svg';
    import { fade } from 'svelte/transition';
    import replayButton from '../assets/back-button.svg';
    import homeButton from '../assets/home.svg';
    import publishButton from '../assets/publish.svg';
    import { useNavigate } from 'svelte-navigator';
    import { isLoggedIn } from '../store/loginV2';

    export let play: () => void;
    export let restart: () => void;
    export let slideState: SlideDisplayState;

    const navigate = useNavigate();
    function goHome() {
        navigate('/');
    }

    function goPublish() {
        navigate(
            `/publish-presentation/${slideState.presentation.presentationId}`
        );
    }

    $: showPlay = slideState.tag === SlideDisplayTag.FIRST_SLIDE_PAUSED;
    $: showDone = slideState.tag === SlideDisplayTag.LAST_SLIDE_FINISHED;
</script>

{#if showPlay}
    <div class="play heartbeat" out:fade={{ duration: 1000 }}>
        <button class="text-white icon-button" on:click={play}
            ><img
                id="play-button"
                class="icon"
                src={playButton}
                alt="play"
            /></button
        >
    </div>
{:else if showDone}
    <div class="play" in:fade={{ duration: 1000 }}>
        <button
            id="restart-button"
            class="text-white icon-button down"
            on:click={restart}
            ><img class="icon" src={replayButton} alt="replay" /></button
        >
        <button
            id="go-home-button"
            class="text-white icon-button down"
            on:click={goHome}
            ><img class="icon" src={homeButton} alt="go home" /></button
        >
        {#if $isLoggedIn}
            <button
                id="publish-button"
                class="text-white icon-button down"
                on:click={goPublish}
                ><img class="icon" src={publishButton} alt="publish" /></button
            >
        {/if}
    </div>
{/if}

<style>
    .play {
        position: absolute;
        width: 100dvw;
        height: 100dvh;
        display: flex;
        flex-direction: row;
        gap: 5dvw;
        justify-content: center;
        align-items: center;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .icon-button {
        all: unset;
        cursor: pointer;
    }

    .icon {
        width: 10dvw;
    }

    .heartbeat {
        animation: heartbeat 1.5s ease-in-out infinite;
    }

    @keyframes heartbeat {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }

    .down {
        transform: translateY(25dvh);
    }
</style>
