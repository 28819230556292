import '../../node_modules/normalize.css/normalize.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/inter';
import App from './App.svelte';
import { initializeI18n } from '../common/service/i18n';

initializeI18n();

const app = new App({
    target: document.getElementById('app') as Element,
});
export default app;
