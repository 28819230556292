<script lang="ts">
    import CreatePresentation from '../../common/createPresentation/CreatePresentation.svelte';
    import { authenticator } from '../store/loginV2';
    import { CreatePresentationStateTag } from '../../common/createPresentation/logic/stateMachine';
    import NewPresentationForm from './generate/NewPresentationForm.svelte';
    import Generating from './generate/Generating.svelte';
    import Redirect from '../../common/Redirect.svelte';
    import Error from './generate/Error.svelte';
    import Denied from './generate/Denied.svelte';
    import Quota from './generate/Quota.svelte';
    import { _ } from 'svelte-i18n';
</script>

<div class="wrapper">
    <h2>{$_('landingpage.newPresentation.title')}</h2>
    <br />
    <CreatePresentation {authenticator} let:state>
        {#if state.tag === CreatePresentationStateTag.IDLE}
            <NewPresentationForm
                generatePresentation={state.createPresentation}
            />
        {:else if state.tag === CreatePresentationStateTag.CREATING}
            <Generating progress={state.progress} />
        {:else if state.tag === CreatePresentationStateTag.ERROR}
            <Error retry={state.retry} />
        {:else if state.tag === CreatePresentationStateTag.DENIED}
            <Denied retry={state.retry} reason={state.reason} />
        {:else if state.tag === CreatePresentationStateTag.QUOTA_EXCEEDED}
            <Quota />
        {:else if state.tag === CreatePresentationStateTag.CREATED}
            <Redirect to={`/view/${state.presentationId}/new`} />
        {/if}
    </CreatePresentation>
</div>

<style>
    .wrapper {
        margin-top: 1rem;
        padding: 1rem;
    }
</style>
