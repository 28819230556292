<script lang="ts">
    import {
        connectedInput,
        virtualKeyboardEnabled,
    } from '../store/virtualKeyboard';
    import { Input } from 'sveltestrap';
    import { onMount } from 'svelte';
    import { get } from 'svelte/store';
    import type { InputType } from 'sveltestrap/src/Input';

    export let value: string;
    export let id: string;
    export let name: string;
    export let placeholder: string | undefined = undefined;
    export let required: boolean = false;
    export let type: InputType | undefined = 'text';
    export let readonly: boolean = false;
    export let valid: boolean | undefined = undefined;
    export let maxlength: number | undefined = undefined;
    export let bsSize: 'lg' | 'sm' | undefined = undefined;
    export let autofocus: boolean | undefined = undefined;
    let className = '';
    export { className as class };

    function onFocus(event: Event) {
        const element = event.target;
        if (element instanceof HTMLInputElement) {
            element.value = value;
            connectedInput.set({
                setValue: (newVal) => {
                    value = newVal;
                    element.value = newVal;
                },
                element,
            });
        }
    }

    function onBlur() {
        connectedInput.set(undefined);
    }

    let containerElement: HTMLElement;
    onMount(() => {
        const isVirtualKeyboard = get(virtualKeyboardEnabled);
        if (isVirtualKeyboard && containerElement) {
            const input = containerElement.querySelector(
                'input[data-tag="input-element"]'
            ) as HTMLInputElement | null;
            if (input) {
                input.value = value;
            }
        }
    });
</script>

{#if $virtualKeyboardEnabled}
    <div bind:this={containerElement} class="osk-input-wrapper">
        <Input
            data-tag="input-element"
            class={className}
            {type}
            {id}
            {name}
            {placeholder}
            {required}
            {readonly}
            {valid}
            {maxlength}
            {bsSize}
            {autofocus}
            on:focus={onFocus}
            on:blur={onBlur}
        />
    </div>
{:else}
    <Input
        class={className}
        {type}
        {id}
        {name}
        {placeholder}
        {required}
        {readonly}
        {valid}
        {maxlength}
        {bsSize}
        {autofocus}
        bind:value
    />
{/if}

<style>
    .osk-input-wrapper {
        display: contents;
    }
</style>
