import type {
    PresentationJson,
    PresentationJsonSlide,
} from './presentationJson';

type SlideDisplayStateTemplate<Tag extends string> = {
    tag: Tag;
    presentation: PresentationJson;
    currentSlide: PresentationJsonSlide;
    slideIndex: number;
};

export enum SlideDisplayTag {
    FIRST_SLIDE_PAUSED = 'FIRST_SLIDE_PAUSED',
    FIRST_SLIDE_PLAYING = 'FIRST_SLIDE_PLAYING',
    PLAYING = 'PLAYING',
    PAUSED = 'PAUSED',
    LAST_SLIDE_PAUSED = 'LAST_SLIDE_PAUSED',
    LAST_SLIDE_PLAYING = 'LAST_SLIDE_PLAYING',
    LAST_SLIDE_FINISHED = 'LAST_SLIDE_FINISHED',
}
export type FirstSlidePaused =
    SlideDisplayStateTemplate<SlideDisplayTag.FIRST_SLIDE_PAUSED>;
export type FirstSlidePlaying =
    SlideDisplayStateTemplate<SlideDisplayTag.FIRST_SLIDE_PLAYING>;
export type PlayingSlide = SlideDisplayStateTemplate<SlideDisplayTag.PLAYING>;
export type PausedSlide = SlideDisplayStateTemplate<SlideDisplayTag.PAUSED>;
export type LastSlidePaused =
    SlideDisplayStateTemplate<SlideDisplayTag.LAST_SLIDE_PAUSED>;
export type LastSlidePlaying =
    SlideDisplayStateTemplate<SlideDisplayTag.LAST_SLIDE_PLAYING>;
export type LastSlideFinished =
    SlideDisplayStateTemplate<SlideDisplayTag.LAST_SLIDE_FINISHED>;

export type SlideDisplayState =
    | FirstSlidePaused
    | FirstSlidePlaying
    | PlayingSlide
    | PausedSlide
    | LastSlidePaused
    | LastSlidePlaying
    | LastSlideFinished;
