<script lang="ts">
    import RenderPresentation from '../../common/renderPresentation/RenderPresentation.svelte';
    import KeyboardControls from '../../common/renderPresentation/KeyboardControls.svelte';
    import PresentationControls from './PresentationControls.svelte';
    import AudioVideoPlayer from '../../common/renderPresentation/AudioVideoPlayer.svelte';
    import { z } from 'zod';
    import { apiDecoder } from '../../common/api';
    import Party from '../misc/Party.svelte';

    export let presentationId: string;
    export let isNew = false;

    const presentationKind = fetch(
        `${document.location.origin}/presentations/${presentationId}/index.json`
    )
        .then((response) => response.json())
        .then((data) =>
            z
                .object({ kind: apiDecoder.PresentationKind })
                .transform(({ kind }) => kind)
                .catch(apiDecoder.PresentationKind.enum.PRESENTATION)
                .parse(data)
        );
</script>

{#await presentationKind}
    loading...
{:then kind}
    {#if kind === apiDecoder.PresentationKind.enum.DALLIC_PARTY}
        <Party id={presentationId} />
    {:else}
        <RenderPresentation
            presentationIndexURL={`${document.location.origin}/presentations/${presentationId}/index.json`}
        >
            <svelte:fragment
                slot="controls"
                let:nextSlide
                let:previousSlide
                let:pause
                let:play
                let:restart
                let:slideState
            >
                <AudioVideoPlayer
                    {slideState}
                    {nextSlide}
                    {play}
                    autoPlay={false}
                    slideTransitionDelay={500}
                />
                <KeyboardControls
                    {nextSlide}
                    {previousSlide}
                    {pause}
                    {play}
                    {restart}
                    {slideState}
                />
                <PresentationControls {play} {slideState} {restart} />
            </svelte:fragment>
        </RenderPresentation>
    {/if}
{:catch error}
    Error {error}!
{/await}
