import staticConfig from './server/config.json';
import dynamicConfig from './aws-config.json';
import dynamicConfigDev from './aws-config-develop.json';
import dynamicConfigMuseum from './aws-config-museum.json';
import type { JWK } from 'jose';

export type Config = StaticConfig & {
    namePrefix: string;
    s3Bucket: string;
    secretName: string;
    userPoolClientId: string;
    userPoolId: string;
    restrictToSimplePresentations: boolean;
    presentationQuota: false | number;
    webauthn:
        | false
        | {
              credentialsAttribute: string;
              relyingParty: {
                  id: string;
                  name: string;
              };
              jwtPublicKey: JWK;
              jwtAlgorithm: string;
          };
};

enum Environment {
    PRODUCTION = 'production',
    DEVELOP = 'develop',
    MUSEUM = 'museum',
}

const dynamicConfigByEnv = {
    [Environment.PRODUCTION]: dynamicConfig,
    [Environment.DEVELOP]: dynamicConfigDev,
    [Environment.MUSEUM]: dynamicConfigMuseum,
} satisfies Record<Environment, unknown>;

const DeploymentPrefix = {
    [Environment.PRODUCTION]: 'PptAi' as const,
    [Environment.DEVELOP]: 'PptAiDev' as const,
    [Environment.MUSEUM]: 'PptAiMuseum' as const,
} satisfies Record<Environment, string>;

type StaticConfig = Omit<typeof staticConfig, 'deployment'> & {
    deployment: (typeof staticConfig)['deployment'][Environment];
};

export const DEPLOYMENT_ENVIRONMENT = 'DEPLOYMENT_ENVIRONMENT';

export function getStage() {
    const env = process.env.DEPLOYMENT_ENVIRONMENT;
    if (env === Environment.PRODUCTION) {
        return Environment.PRODUCTION;
    }
    if (env === Environment.MUSEUM) {
        return Environment.MUSEUM;
    }
    return Environment.DEVELOP;
}

export function getConfig<
    Prefix extends (typeof DeploymentPrefix)[Environment] = 'PptAi'
>(): Config {
    const env = getStage();
    const dConfig = dynamicConfigByEnv[env];
    const prefix = DeploymentPrefix[getStage()] as Prefix;
    const { deployment, ...conf } = staticConfig;
    return {
        ...conf,
        deployment: deployment[env],
        namePrefix: prefix,
        restrictToSimplePresentations: env === Environment.MUSEUM,
        webauthn:
            env === Environment.MUSEUM
                ? {
                      credentialsAttribute: 'publicKeyCred',
                      relyingParty: {
                          name: 'AI Slides',
                          id: 'museum.ai-slides.com',
                      },
                      jwtPublicKey: {
                          kty: 'RSA',
                          n: '0eqswO6YBTvB6Fae-3OWpQn68kFTJMfZuyyrhvfJFy7VjEbJN_JSh0tEvFS3hjEvm0xl1ox5LaFcp-UsW5kbyWSWvoLv3VQkYGUHgubWWtOIzRMqwYS5-vDirTOyPzxKwlT8yR3SRnD9oeBw6yUibsa3ukmWBUzsDMjhtJFytel4vunNKlzdJQ3m1BXtPM3UEeEc9NhxlQGmI7o9FMRkQKz7CtdSoq-OnPJNLUb8zEDPZbse0L-3zgXgO--4iXdI10co10G0nOLC9UgD8S1LxXR2S4HoZDIv8-FtQwqCWHJi7aP4zWJpampfoLQGXZntdgNji5L26BDtlBNRdyUQCQ',
                          e: 'AQAB',
                      },
                      jwtAlgorithm: 'RS256',
                  }
                : false,
        presentationQuota: env === Environment.MUSEUM ? 600 : false,
        s3Bucket:
            // @ts-expect-error -- yeah yeah
            dConfig[`${prefix}BucketStack`]?.[`${prefix}bucketName`] ?? '',
        secretName:
            // @ts-expect-error -- yeah yeah
            dConfig[`${prefix}SecretStack`]?.[`${prefix}secretName`] ?? '',
        userPoolClientId:
            // @ts-expect-error -- yeah yeah
            dConfig[`${prefix}UserPoolStack`]?.[`${prefix}userPoolClientId`] ??
            '',
        userPoolId:
            // @ts-expect-error -- yeah yeah
            dConfig[`${prefix}UserPoolStack`]?.[`${prefix}userPoolId`] ?? '',
    };
}
