<script lang="ts">
    import type { SlideDisplayState } from '../../../common/renderPresentation/logic/slideDisplay';
    import { SlideDisplayTag } from '../../../common/renderPresentation/logic/slideDisplay';
    import { Button } from 'sveltestrap';
    import { _ } from 'svelte-i18n';

    export let slideState: SlideDisplayState;
    export let restart: () => void;
    export let goBack: () => void;
    export let play: () => void;

    $: showPlay = slideState.tag === SlideDisplayTag.FIRST_SLIDE_PAUSED;
    $: isLastPage = slideState.tag === SlideDisplayTag.LAST_SLIDE_FINISHED;
</script>

<div class="control-panel">
    {#if showPlay}
        <div class="dummy">&nbsp;</div>
        <Button color="primary" class="text" on:click={play} size="lg">
            {$_('story.playButtonText')}
        </Button>
    {:else if isLastPage}
        <Button color="secondary" class="text" on:click={restart} size="lg">
            {$_('story.hearAgainButtonText')}
        </Button>
        <Button color="primary" class="text" on:click={goBack} size="lg">
            {$_('story.backButtonText')}
        </Button>
    {/if}
</div>

<style>
    .control-panel {
        width: 100dvw;
        position: fixed;
        bottom: 18dvh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10dvw;
    }

    .dummy {
        width: 10dvw;
    }
</style>
