<script lang="ts">
    import Book from './Book.svelte';
    import BookPageContent from './BookPageContent.svelte';
    import type { SlideDisplayState } from '../../../logic/slideDisplay';

    export let creatorImage: string;
    export let slideState: SlideDisplayState;

    $: page = {
        lhs: {
            image: slideState.currentSlide.imagePath,
            text: slideState.currentSlide.headline,
        },
        rhs: { text: slideState.currentSlide.text },
        audio: slideState.currentSlide.audioPath,
    };
</script>

<Book>
    <svelte:fragment slot="lhs">
        {#if page?.lhs !== undefined}
            <BookPageContent {...page.lhs} />
        {:else}
            &nbsp;
        {/if}
    </svelte:fragment>
    <svelte:fragment slot="rhs">
        {#if page?.rhs !== undefined}
            <BookPageContent {...page.rhs} />
        {:else}
            &nbsp;
        {/if}
    </svelte:fragment>
</Book>
<div class="robot-wrapper">
    <img src={creatorImage} alt="" class="reader" />
</div>

<style>
    .robot-wrapper {
        position: fixed;
        bottom: 0;
        right: 0;
    }

    .reader {
        height: 55svh;
        position: relative;
        left: 13svh;
    }
</style>
